import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import { extendTheme } from '@chakra-ui/react'
import { CustomChakraProvider } from '@components/CustomChakraProvider/CustomChakraProvider'
import { FacebookPixel } from '@components/FacebookPixel'
import { ModalProvider } from '@components/Modal/provider'
import createCache from '@emotion/cache'
import CookieProvider from '@src/react-context/cookie-context'
import { PartnersProvider } from '@src/react-context/partners-context'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { SWRConfig } from 'swr'
import '../styles/globals.css'

declare global {
  interface HTMLScriptElement {
    nonce?: string
  }
}

export const theme = extendTheme({
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  },
  colors: {
    gray: {
      50: '#F4F4F7',
      100: '#F0F0F0',
      150: '#DCDDE3',
      200: '#C6C8D1',
      300: '#B0B3C0',
      400: '#A0A4B3',
      500: '#7C8096',
      600: '#646981',
      700: '#4D5163',
      800: '#363843',
      900: '#0F2233',
    },
    blue: {
      50: '#EAF6FF',
      500: '#0D71F0',
      600: '#0A5AC0',
      800: '#052D5F',
    },
    orange: {
      500: '#FF9D2E',
    },
  },
})

function App({ Component, pageProps, gtmId, fbPixelId }) {
  const nonce = pageProps.nonce

  const emotionCache = createCache({
    key: 'css',
    nonce,
  })

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }

    const applyNonceToScripts = () => {
      if (typeof window !== 'undefined' && nonce) {
        const scripts = document.querySelectorAll('script')
        scripts.forEach(script => {
          if (script instanceof HTMLScriptElement && !script.nonce && !script.hasAttribute('data-nonce-handled')) {
            script.nonce = nonce
            script.setAttribute('data-nonce-handled', 'true')
          }
        })

        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            mutation.addedNodes.forEach(node => {
              if (node.nodeName === 'SCRIPT' && node instanceof HTMLScriptElement && !node.nonce) {
                node.nonce = nonce
                node.setAttribute('data-nonce-handled', 'true')
              }
            })
          })
        })

        observer.observe(document.documentElement, {
          childList: true,
          subtree: true,
        })

        return () => observer.disconnect()
      }
    }

    applyNonceToScripts()
  }, [nonce, gtmId])
  return (
    <>
      <SWRConfig value={{ revalidateOnFocus: false }}>
        <ThemeProvider>
          <CustomChakraProvider theme={theme} nonce={nonce}>
            <CookieProvider>
              <CSSReset />
              <ModalProvider openYourAccountAlert={pageProps?.openYourAccountAlert}>
                <PartnersProvider>
                  <Component {...pageProps} />
                </PartnersProvider>
              </ModalProvider>

              <script
                src={'https://unpkg.com/blip-chat-widget'}
                type={'text/javascript'}
                integrity={'sha384-ouECQb0S113KuvY42WfQQJyQG5GCD4zY6tLs6N4q7F/sPGHHk2W0RKmYG2HtCbr7'}
                crossOrigin={'anonymous'}
                nonce={nonce}
                data-nonce-handled="true"
              ></script>
            </CookieProvider>
          </CustomChakraProvider>
        </ThemeProvider>
      </SWRConfig>
      <FacebookPixel fbPixelId={fbPixelId} nonce={nonce} />
    </>
  )
}

App.getInitialProps = async appContext => {
  const nonce = appContext.ctx.req?.headers['x-nonce'] as string

  return {
    gtmId: process.env.GTM_ID,
    fbPixelId: process.env.FB_PIXEL_ID,
    nonce,
    pageProps: {
      ...(appContext.Component.getInitialProps ? await appContext.Component.getInitialProps(appContext.ctx) : {}),
      nonce,
    },
  }
}
export default App
