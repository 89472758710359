var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"A-kGm9ZD0aEHXG8DmQSvT"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
export const configSentry = {
  environment: process.env.NEXT_PUBLIC_SENTRY_APP_ENV,

  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  enabled: true,
}
Sentry.init(configSentry)
Sentry.setTag('location', 'client')
