// components/CustomChakraProvider.tsx
import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react'
import createCache, { EmotionCache } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import React from 'react'

interface CustomChakraProviderProps extends ChakraProviderProps {
  nonce?: string
  emotionCache?: EmotionCache
}

export const CustomChakraProvider: React.FC<CustomChakraProviderProps> = ({
  children,
  nonce,
  emotionCache: propCache,
  ...rest
}) => {
  const cache =
    propCache ||
    createCache({
      key: 'css',
      nonce,
    })

  return (
    <CacheProvider value={cache}>
      <ChakraProvider {...rest}>{children}</ChakraProvider>
    </CacheProvider>
  )
}
