import { useEffect } from 'react'

interface FacebookPixelProps {
  fbPixelId: string
  nonce?: string
}

declare global {
  interface Window {
    fbq: {
      (
        method: 'init' | 'track' | 'trackCustom' | 'trackSingle' | 'trackSingleCustom',
        eventName: string,
        params?: Record<string, any>
      ): void
      callMethod?: (...args: any[]) => void
      queue?: any[]
      loaded?: boolean
      version?: string
      push?: any
    }
    _fbq: any
  }
}

export const FacebookPixel: React.FC<FacebookPixelProps> = ({ fbPixelId, nonce }) => {
  useEffect(() => {
    if (!fbPixelId) return

    if (!window.fbq) {
      window.fbq = function () {
        // @ts-ignore
        window.fbq.callMethod ? window.fbq.callMethod.apply(window.fbq, arguments) : window.fbq.queue.push(arguments)
      }

      window._fbq = window._fbq || window.fbq
      window.fbq.push = window.fbq
      window.fbq.loaded = true
      window.fbq.version = '2.0'
      window.fbq.queue = []
    }

    if (!document.getElementById('facebook-pixel')) {
      const script = document.createElement('script')
      script.id = 'facebook-pixel'
      script.src = `https://connect.facebook.net/en_US/fbevents.js`
      script.async = true
      if (nonce) script.nonce = nonce
      document.head.appendChild(script)
    }

    window.fbq('init', fbPixelId)
    window.fbq('track', 'PageView')

    return () => {}
  }, [fbPixelId, nonce])

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src={`https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1`}
      />
    </noscript>
  )
}
